.NotFound {
    padding-top: 100px;
    text-align: center;
  }
.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
  }
  @-webkit-keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
  }
  @keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
  }
.spacer{
    height: 100px;
}

.status-complete{
    background-color: #c8e6c9;
}

.status-approval{
    background-color: #FFDEEC;
}

.due-date{
    font-weight: bold; 
    color: red;    
}
.status-complete{
    background-color: #c8e6c9;
}

.status-in-progress{
    background-color: #FFF047;
}

.status-not-started{
    background-color: #FFFFFF;
}
.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }
.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

