.spacer{
    height: 100px;
}

.status-complete{
    background-color: #c8e6c9;
}

.status-approval{
    background-color: #FFDEEC;
}

.due-date{
    font-weight: bold; 
    color: red;    
}