.status-complete{
    background-color: #c8e6c9;
}

.status-in-progress{
    background-color: #FFF047;
}

.status-not-started{
    background-color: #FFFFFF;
}